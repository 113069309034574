/* global Sentry */

LS = {
	state: {
		cropped: false
	},
	stash: {
		fetch: [],
		maps: [],
		street: null
	},
	formats: {
		sales: {
			label: 'Sale',
			heading: 'Land for sale',
			acquire: 'buy',
			acquiring: 'buying',
			dispose: 'sell',
			disposing: 'selling',
			price: 'list price'
		},
		auctions: {
			label: 'Auction',
			heading: 'Land auctions',
			acquire: 'buy',
			acquiring: 'buying',
			dispose: 'auction',
			disposing: 'auctioning',
			price: 'minimum bid'
		},
		leases: {
			label: 'Lease',
			heading: 'Land for lease',
			acquire: 'lease',
			acquiring: 'leasing',
			dispose: 'lease',
			disposing: 'leasing',
			price: 'lease rate'
		}
	},
	types: {
		residential: 'Residential',
		commercial: 'Commercial',
		agricultural: 'Agricultural',
		recreational: 'Recreational'
	},
	criteria: {
		'Geography': {
			'beachfront': 'Beachfront',
			'desert': 'Desert',
			'island': 'Island',
			'lakefront': 'Lake property',
			'mountain': 'Mountain',
			'off-grid': 'Off-grid',
			'resort': 'Resort',
			'riverfront': 'River property',
			'rural': 'Rural',
			'waterfront': 'Waterfront'
		},
		'Land use': {
			'cattle-ranch': 'Cattle ranch',
			'chicken-farm': 'Chicken farm',
			'dairy-farm': 'Dairy farm',
			'development': 'Development',
			'homestead': 'Homestead',
			'industrial': 'Industrial',
			'investment': 'Investment',
			'mixed-use': 'Mixed-use',
			'organic-farm': 'Organic farm',
			'ranch': 'Ranch',
			'row-crop': 'Row crop',
			'rv-lot': 'RV lot',
			'hobby-farm': 'Small farm',
			'timberland': 'Timber'
		},
		'Structures': {
			'a-frame-house': 'A-frame house',
			'barn': 'Barn',
			'barndominium': 'Barndominium',
			'bunker': 'Bunker',
			'cabin': 'Cabin',
			'cottage': 'Cottage',
			'estate': 'Estate',
			'farm-house': 'Farm house',
			'house': 'House',
			'lodge': 'Lodge',
			'mobile-home': 'Mobile home',
			'tiny-home': 'Tiny home',
			'workshop': 'Workshop'
		},
		'Improvements': {
			'airstrip': 'Airstrip',
			'fenced': 'Fenced',
			'irrigated': 'Irrigated',
			'septic': 'Septic system',
			'solar-power': 'Solar power',
			'water-well': 'Water well'
		},
		'Features': {
			'cave': 'Cave',
			'clear-cut': 'Clear-cut',
			'creek': 'Creek',
			'mineral-rights': 'Mineral rights',
			'oil': 'Oil',
			'orchard': 'Orchard',
			'pasture': 'Pasture',
			'pond': 'Pond',
			'spring': 'Spring',
			'stream': 'Stream',
			'water-rights': 'Water rights',
			'waterfall': 'Waterfall',
			'wetland': 'Wetland',
			'wooded': 'Wooded',
			'vineyard': 'Vineyard'
		},
		'Activities': {
			'borders-public-land': 'Borders public',
			'camping': 'Camping',
			'conservation': 'Conservation',
			'equestrian': 'Horse property',
			'fishing': 'Fishing',
			'golf-course-lot': 'Golfing',
			'hunting': 'Hunting',
			'waterfowl': 'Waterfowl'
		},
		'Attributes': {
			'by-owner': 'By owner',
			'cheap': 'Cheap',
			'distressed': 'Distressed',
			'historic': 'Historic',
			'new-construction': 'New construction',
			'owner-financing': 'Owner financed',
			'rent-to-own': 'Rent to own',
			'sustainable': 'Sustainable',
			'vacant': 'Undeveloped',
			'unrestricted': 'Unrestricted'
		}
	},
	directory: {
		agents: [],
		lenders: [],
		services: [
			'appraisal',
			'architecture',
			'auction',
			'consulting',
			'demolition',
			'energy',
			'engineering',
			'environmental',
			'equestrian',
			'equipment',
			'excavation',
			'fencing',
			'general-contracting',
			'home-building',
			'land-buying',
			'land-selling',
			'landscaping',
			'leasing',
			'legal',
			'management',
			'minerals',
			'oil-gas',
			'paving',
			'photography',
			'septic',
			'surveying',
			'timber',
			'well-drilling',
			'wildlife-management'
		]
	}
};

LS.tags = {};

Object.keys(LS.criteria).forEach(function(key) {
	$.extend(LS.tags, LS.criteria[key]);
});

LS.error = {
	/**
	 * Initialize reporting
	 */
	init: function() {
		if (typeof Sentry === 'undefined') {
			return;
		}

		Sentry.init({
			autoSessionTracking: false,
			environment: $.env(),
			release: $.get('versions.core'),
			allowUrls: [
				/(.+)\.landsearch\.(com|io)/
			],
			ignoreErrors: [
				'AbortError',
				'Illegal invocation',
				'Invalid character',
				'Non-Error',
				'NotAllowed',
				'Object.entries',
				'Object.fromEntries',
				'ResizeObserver',
				'restricted frame',
				'Script error',
				'setIOSParameters',
				'Unexpected token',
				'Unspecified'
			]
		});
	},

	/**
	 * Process errors through integrated services
	 *
	 * @param {String} message
	 * @param {String} [cause]
	 * @param {Object} [tags]
	 * @param {Object} [meta]
	 */
	report: function(message, cause, tags, meta) {
		if (typeof Sentry === 'undefined') {
			return;
		}

		var data = {};

		if (meta) {
			data.contexts = {
				Meta: meta
			};
		}

		if (tags) {
			data.tags = tags;
		}

		Sentry.captureMessage(message + ': ' + (cause || 'No error message'), data);
	}
};

(function() {
	var parts = location.hostname.split('.'),
		ext = parts[2],
		domain = parts[1] + '.' + ext,
		pre = 'https://';

	$.env({
		production: function() {
			return ext === 'com';
		},
		staging: function() {
			return ext === 'io';
		}
	});

	$.merge({
		appUrl: pre + 'www.' + domain,
		apiUrl: pre + 'api.' + domain + '/v1',
		cdnUrl: pre + 'cdn.' + domain,
		papiUrl: pre + 'papi.' + domain + '/v1'
	});

	LS.error.init();
})();